#sp-img{  
  width: 40vh;
  height: 40vh;      
  top: 15vh;
  left: -5vh;  
  right: 0;
  bottom: 0;
  margin: 5vh 0 0 15vh;   
  position: absolute;
}

#company-name, #company-slogen{
  text-shadow: 0 2px 4px #222;
}

  .chemical-element {
    width: 165px;
    height: 165px;
    position: relative;
    font: bold 95px Arial;
    color: #fafffb;
    padding: 25px 25px 25px 30px;
    border: 3px solid #DDFFE2;
    display: inline-block;
    background: linear-gradient(-45deg, #5cbe73 1%, #182b04 100%);
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.15);
  }
  


  .chemical-element:nth-child(4) {
    margin-left: 165px;
    padding-left: 23px;
  }

  .desc {
    position: absolute;
    font: bold 13px Arial;
    color: rgba(244, 247, 245, 0.95);
  }
  
  .big {
    font: bold 20px Arial;
  }
  
  .medium {
    font: bold 17px Arial;
  }
  
  .topLeft {
    top: 8px;
    left: 10px;
  }
  
  .topRight {
    top: 8px;
    right: 5px;
    text-align: right;
    width: 25px;
  }
  
  .bottomLeft-1 {
    bottom: 20px;
    left: 5px;
    text-align: left;
  }
  
  .bottomLeft-2 {
    bottom: 5px;
    left: 5px;
    text-align: left;
  }

.title-1 {
  font: bold 4rem Arial;
  color: #fafffb;
}

@media only screen and (max-width: 500px) {
  .title-1 {
    font: bold 3rem Arial;
    color: #fafffb;
  }
}

/*.card { background: linear-gradient(315deg, #4EA262 1%, #1A2E06 100%)!important; }*/
.card { 
  /*background: linear-gradient(315deg, #4EA262 20%, #1A2E06 100%)!important;  */
  background: #222!important;
  border: 2px solid #FFA81E;
}
.card-header, .card-footer { opacity: 1}