.rightSpacer{
    border-right: solid 2px;
}
.nav-link{
    font-size: 1.2rem;    
    margin: 0.2rem;
    padding: 0;    
}

.navbar-dark .navbar-nav .nav-link{
    color: #ffa81e;
}

.navbar-dark .navbar-brand{
    color: #ffa81e;
    border-radius: 50%;
    border: solid #ffa81e 1px;
    width: 50px;
}

.navbar-dark .navbar-toggler, .navbar-toggler-icon {
    color: #ffa81e;
    border-color:#ffa81e;
}

.bg-dark {
    opacity: 0.9;
}
