
#grid {
    position: relative;
    width: 100%;
    margin: 0 auto;
    padding: 0; /* Clears unordered list default of 40px */
}

.clear:after {
    content: "";
    display: block;
    clear: both;
}

#grid li {
    list-style-type: none;
    position: relative;
    float: left;
    width: 12.85714285714286%;
    padding: 0 0 14.87860145166612% 0;
    -o-transform: rotate(-60deg) skewY(30deg);
    -moz-transform: rotate(-60deg) skewY(30deg);
    -webkit-transform: rotate(-60deg) skewY(30deg);
    -ms-transform: rotate(-60deg) skewY(30deg);
    transform: rotate(-60deg) skewY(30deg);
    overflow: hidden;
    visibility: hidden;
}

#grid li .hexagon {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: orangered;
    -o-transform: skewY(-30deg) rotate(60deg);
    -moz-transform: skewY(-30deg) rotate(60deg);
    -webkit-transform: skewY(-30deg) rotate(60deg);
    -ms-transform: skewY(-30deg) rotate(60deg);
    transform: skewY(-30deg) rotate(60deg);
    overflow: hidden;
}

#grid li * {
    visibility: visible;
}

#grid li:nth-child(1n+1) {
  margin: 0.5%;
}

#grid li:nth-child(14n+8), #grid li:nth-child(14n+9), #grid li:nth-child(14n+10), #grid li:nth-child(14n+11), #grid li:nth-child(14n+12), #grid li:nth-child(14n+13), #grid li:nth-child(14n+14) {
    margin-top: -2.9285714285%;
    margin-bottom: -2.9285714285%;
    -o-transform: translateX(50%) rotate(-60deg) skewY(30deg);
    -moz-transform: translateX(50%) rotate(-60deg) skewY(30deg);
    -webkit-transform: translateX(50%) rotate(-60deg) skewY(30deg);
    -ms-transform: translateX(50%) rotate(-60deg) skewY(30deg);
    transform: translateX(50%) rotate(-60deg) skewY(30deg);
}

#grid li:nth-child(14n+8) { 
    margin-left: 1%;
}

#grid li .black {
    background: #444;
}

#grid li .teal {
    background: teal;
}

#grid li .hexagon img {
    left:-100%;
    right:-100%;    
    height:30%;
    width: auto;
    margin: 40% auto 0;
    -o-transition: all 0.6s linear 0s; 
    -moz-transition: all 0.6s linear 0s; 
    -ms-transition: all 0.6s linear 0s; 
    -webkit-transition: all 0.6s linear 0s; 
    transition: all 0.6s linear 0s;
  }
  
  #grid li:hover img {
    height: 20%;    
    -o-transform: translateZ(0) translateX(-0%);
    -moz-transform: translateZ(0) translateX(-0%);
    -webkit-transform: translateZ(0) translateX(-0%);
    -ms-transform: translateZ(0) translateX(-0%);
    transform: translateZ(0) translateX(-0%);
  }
  
  #grid li .hexagon .overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(255,255,255,0);
  }
  
  #grid li:hover .overlay {
    -o-transition: all 0.6s linear 0s; 
    -moz-transition: all 0.6s linear 0s; 
    -ms-transition: all 0.6s linear 0s; 
    -webkit-transition: all 0.6s linear 0s; 
    transition: all 0.6s linear 0s;
  }

 @-webkit-keyframes heartbeat {
    from {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    10% {
      -webkit-transform: scale(0.91);
              transform: scale(0.91);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    17% {
      -webkit-transform: scale(0.98);
              transform: scale(0.98);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    33% {
      -webkit-transform: scale(0.87);
              transform: scale(0.87);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    45% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
  }

  .wikipanel{
    
  }