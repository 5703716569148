canvas{
    position: absolute; 
    top:0;
    left:0;  
    z-index: -1;
  }
   
  body {     
    background-color: #222;  
    padding: 0 0 0 1rem; 
    margin: 5rem 0 0 0;
    color: white;  
    overflow-x: scroll;
    overflow-y: scroll;
  }
  .glow{
    color: #fff; 
    text-shadow: 0 0 20px #fff, 0 0 30px #ff4da6, 0 0 40px #ff4da6, 0 0 50px #ff4da6, 0 0 60px #ff4da6, 0 0 70px #ff4da6, 0 0 80px #ff4da6;
  }